.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.box {
  margin-top: 2%;
  width: 50%;
  color: var(--text);
}

.box h1 {
  color: var(--bigTitle);
  text-align: center;
  margin-bottom: 20px;
}

.box h3 {
  color: var(--titles);
  margin-bottom: 5px;
}

.box ul {
  margin-left: 7%;
}

.box p {
  margin-left: 2%;
}

footer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

footer ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

footer ul li a {
  color: var(--clickable) !important;
  text-decoration: none;
}

footer div p {
  color: var(--bigTitle);
  margin-top: 10px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .box {
    width: 90%;
  }
}