/*@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');*/

/* --- GLOBAL --- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

.light {
  --background: #eaeff0;
  --secundaryBackground: #ccd3d4;
  --menuBackground: #ffffff;
  --menuText: #272635;
  --bigTitle: #181725;
  --titles: #35334e;
  --text: #626077;
  --lightText: #c4c4db;
  --clickable: #507080;
  --gradient: #5c39db;
  background-color: var(--background);

  --sb-track-color: #23292b;
  --sb-thumb-color: #acb9de;
  --sb-size: 3px;
}

.dark {
  --background: #2b3047;
  --secundaryBackground: #191c29;
  --menuBackground: #1f2333;
  --menuText: #F2F2F2;
  --bigTitle: #cdc8ee;
  --titles: #d0e0e9;
  --text: #e2e6e9;
  --lightText: #202024;
  --clickable: #b4dfe2;
  --gradient: #237563;
  background-color: var(--background);

  --sb-track-color: #acb9de;
  --sb-thumb-color: #23292b;
  --sb-size: 3px;
}

body {
  background-color: var(--background);
  overflow-x: hidden;
  max-width: 100vw;
}

.app {
  background-color: var(--background);
  min-height: 100vh;
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
}

body::-webkit-scrollbar {
  width: var(--sb-size);
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
}

.cookie-banner {
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 30%;
  height: auto;
  padding: 13px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 10px;
  text-align: center;
}

.cookie-button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
}

/* --- MENU --- */
header {
  position: fixed;
  width: 100%;
  top: 0;
  margin-top: 10px;
  z-index: 9;
}

#main-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
}

#mobile-main-menu {
  display: none;
}

#page-theme-box {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--menuBackground);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}

#page-theme-box:hover {
  transform: scale(1.05);
}

#theme-icon {
  width: 25px;
  height: 25px;
}

#header-box {
  display: flex;
  justify-content: center;
  width: 400px;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  background-color: var(--menuBackground);
  border-radius: 7px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#header-box ul {
  display: flex;
  width: 100%;
  gap: 15px;
  justify-content: center;
  align-items: center;
  list-style: none;
}

#header-box li a {
  color: var(--menuText);
  text-decoration: none;
  font-weight: 600;
}

#header-box li a:hover {
  color: var(--clickable);
}

#lang-selector-box {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border-radius: 7px;
  gap: 10px;
  background-color: var(--menuBackground);
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-list {
  position: absolute;
  list-style: none;
  background-color: var(--menuBackground);
  border-radius: 4px;
  width: 100%;
  margin-top: 120px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-list-item {
  display: block;
  width: 100%;
  padding: 10px;
  color: var(--menuText);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdown-header {
  display: flex;
  color: var(--menuText);
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dropdown-arrow  {
  margin-top: 3px;
}

.dropdown-header:hover {
  color: var(--clickable);
}

.dropdown-list-item:hover {
  background-color: var(--background);
  color: var(--clickable);
}

/* --- HOME PAGE --- */
.download-app {
  display: flex;
  color: var(--menuBackground);
  font-weight: 700;
  background: var(--titles);
  background: linear-gradient(90deg, var(--titles) 0%, var(--menuText) 35%, var(--bigTitle) 100%);
  border-radius: 40px;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  padding: 10px;
  padding-left: 20px;
  position: fixed;
  bottom: 5%;
  left: 5%;
  gap: 20px;
  z-index: 9;
  cursor: pointer;
}

.download-app div {
  display: flex;
  flex-direction: column;
}

.download-app-name {
  font-size: 12px;
  font-weight: 300;
  color: var(--lightText);
}

.radial {
  position: absolute;
  background: radial-gradient(var(--gradient) 0%, transparent 70%);
  width: 100%;
  height: 100%;
  opacity: 0.1;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 0;
}

.top {
  top: -50%;
  width: 80%;
  height: 80%;
}

.bottom {
  bottom: -15%;
  opacity: 0.15;
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  top: 0;
  padding-top: 100px;
}

.home h1 {
  color: var(--titles);
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.home p {
  color: var(--text);
  font-size: 17px;
  font-weight: 300;
  text-align: center;
  max-width: 50%;
  margin-top: 20px;
}

.main-image {
  position: absolute;
  height: 70vh;
  width: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-55%, 0%);
  z-index: 1;
}

.full-height-darker {
  background-color: var(--secundaryBackground);
}

.full-height-lighter {
  background-color: var(--background);
}

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: 3%;
  height: 100vh;
}

.content h2 {
  color: var(--bigTitle);
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 3px;
}

.content p {
  color: var(--text);
  margin-top: 15px;
  font-weight: 400;
  font-size: 15px;
  max-width: 40%;
  text-align: center;
}

.content img {
  position: absolute;
  bottom: 0;
  height: 65vh;
  width: auto;
  border-radius: 30px 30px 0 0;
}

.floating-info {
  color: var(--titles);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 10px;
  letter-spacing: 2px;
}

.floating-info p {
  margin-top: -5px;
}

#contact {
  padding: 20px;
  text-align: center;
  padding-bottom: 9vh;
}

.contact-title {
  font-size: 50px;
  color: var(--bigTitle);
  font-weight: 700;
  letter-spacing: 3px;
}

.contact-subtitle {
  font-size: 15px;
  color: var(--titles);
  font-weight: 400;
}

.email {
  font-weight: bold;
  font-size: 17px;
  color: var(--text);
  margin-top: 5px;
}

#contact a {
  position: relative;
  padding: 13px;
  background-color: var(--clickable);
  color: var(--secundaryBackground);
  font-weight: bold;
  text-decoration: none;
  border-radius: 7px;
  top: 7vh;
  transition: all 0.2s;
}

#contact a:hover {
  background-color: var(--bigTitle);
}

.main-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px var(--text) solid;
  box-sizing: border-box;
}

.footer-column {
  width: 30%;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
}

.icons {
  display: flex !important;
  flex-direction: row !important;
  gap: 10px;
}

.footer-column-title {
  font-size: 20px;
  font-weight: 600;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: end;
}

.footer-column ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .cookie-banner {
    position: fixed;
    top: 5px;
    right: 5px;
    left: 5px;
    width: 97%;
    height: fit-content;
    padding: 13px;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 30px;
    border-radius: 10px;
    text-align: center;
  }
  
  .cookie-button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
  }
  #main-menu {
    display: none !important;
  }
  header {
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
  }
  #mobile-main-menu {
    display: flex !important;
    width: 95vw;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--menuBackground);
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  #mobile-main-menu a {
    color: var(--menuText);
    text-decoration: none;
  }
  .row {
    display: flex !important;
    flex-direction: row !important;
    gap: 7px;
  }
  .hamburger-menu {
    position: absolute;
    background-color: var(--menuBackground);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  } 
  .hamburger-menu ul {
    list-style: none;
  }
  .hamburger-menu li {
    display: flex;
    justify-content: center;
    gap: 13;
  }
  .hamburger-menu li img {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .hamburger-menu li a {
    padding: 13px;
    width: 100%;
    background-color: var(--menuBackground);
  }
  .hamburger-menu li a:hover {
    color: var(--clickable);
    background-color: var(--secundaryBackground);
  }
  .active {
    color: var(--clickable) !important;
    font-weight: 600;
  }
  .home {
    padding-top: 7%;
    height: 90vh;
  }
  .home h1 {
    font-size: 35px;
    max-width: 95%;
  }
  .home p {
    max-width: 87%;
  }
  .home img {
    height: 50vh;
    width: auto;
  }
  .content {
    padding-top: 7%;
    height: auto;
    padding-bottom: 95vw;
  }
  .content h2 {
    text-align: center;
    font-size: 30px;
  }
  .content p {
    max-width: 90%;
  }
  .content img {
    height: auto;
    width:95vw;
  }
  .floating-info {
    margin-top: 10px;
    flex-direction: column;
    text-align: center;
  }
  .floating-info p {
    display: none;
  }
  .main-footer {
    flex-direction: column;
    padding-bottom: 100px;
  }
  .footer-row {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .footer-column {
    width: 100%;
    text-align: center;
  }
  .flex-center {justify-content: center;}
  .right {justify-content: center;}
}

@media only screen and (max-width: 370px) {
  .home img {
    height: auto;
    width: 95vw;
  }
}

@media only screen and  (max-width: 1024px) and (min-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .cookie-banner {
    position: fixed;
    bottom: 5px;
    right: 5px;
    width: 50%;
    height: auto;
    padding: 13px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-radius: 10px;
    text-align: center;
  }
  
  .cookie-button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
  }
  #mobile-main-menu {
    display: none;
  }
  .home {
    height: auto;
    padding-bottom: 55vh;
  }
  .home h1 {
    font-size: 35px;
    max-width: 90%;
  }
  .home p {
    max-width: 80%;
  }
  .home img {
    height: 50vh;
    width: auto;
  }
  .content {
    padding-top: 7%;
    height: auto;
    padding-bottom: 90vw;
  }
  .content h2 {
    text-align: center;
    font-size: 30px;
  }
  .content p {
    max-width: 90%;
  }
  .content img {
    height: auto;
    width: 95vw;
  }
  .floating-info {
    margin-top: 10px;
    flex-direction: column;
    text-align: center;
  }
  .floating-info p {
    display: none;
  }
}